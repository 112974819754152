:root {
  --primary-color: #2c3e50;
  --secondary-color: #2980b977;
  --text-color: #ecf0f1;
  --bg-color: #34495e;
}

body {
  background-color: var(--bg-color);
  font-family: Arial, sans-serif;
}

.FakeishNewsComp {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.pageTitle {
  color: var(--text-color);
  margin: 0;
}

.buttonsWrapper {
  display: flex;
  gap: 10px;
}

.toast {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--secondary-color);
  color: var(--text-color);
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
}

button {
  background-color: var(--secondary-color);
  color: var(--text-color);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  transition: background-color 0.2s;
}

button:hover {
  background-color: var(--primary-color);
}

.promptTextArea {
  width: 100%;
  height: 73px;
  resize: none;
  white-space: pre-wrap;
  overflow-y: auto;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid var(--primary-color);
  padding: 10px;
  background-color: var(--text-color);
  color: var(--primary-color);
}

.imageContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  height: 100%;
  width: calc(100vw - 50px); /* adjust this value depending on the height of other elements */
  overflow: hidden;
}

.imageWrapper {
  width: 70%;
  padding: 2px 2px;
  text-align: center;
  position: relative;
  height: 100%;
}

.imageTitle {
  font-size: 14px;
  font-weight: bold;
  color: var(--text-color);
  background-color: var(--secondary-color);
  display: inline-block;
  position: absolute;
  padding: 10px;
  border-radius: 8px;
  left: 50%;
  bottom: 3px; /* or adjust this as needed for vertical position */
  transform: translateX(-50%);
  text-align: center;
}

img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 4px;
}

/* Custom Checkbox Styles */
.checkboxContainer {
  display: flex;
  gap: 10px;
  color: var(--text-color);
}

.checkboxLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkboxInput {
  appearance: none;
  background-color: var(--secondary-color);
  margin-right: 5px;
  outline: none;
  width: 20px;
  height: 20px;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}

.checkboxInput:checked {
  background-color: var(--primary-color);
}

.checkboxInput:checked::after {
  content: '';
  position: absolute;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid var(--text-color);
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
